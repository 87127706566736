import axios from "axios";

let gateway = "";
let activeEnv = "";
if (window.location.hostname === "localhost") {
	gateway = process.env.REACT_APP_BACKEND_GATEWAY;
	activeEnv = process.env.REACT_APP_ACTIVE_ENV;
} else {
	gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
	activeEnv = window._env_.REACT_APP_ACTIVE_ENV;
}

export async function getUserProfile(profileId) {
	const tonicToken = sessionStorage.getItem("id_token");

	const response = await axios({
		method: "get",
		url: gateway + "/customer/cdi/v3/read-profile/PROFILE/" + profileId + "/PROFILE",
		timeout: 20000,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response;
}

export async function getUserProfileId(email) {
	const tonicToken = sessionStorage.getItem("id_token");

	const response = await axios({
		method: "get",
		url: gateway + "/customer/cdi/identity/" + email,
		timeout: 20000,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response;
}

