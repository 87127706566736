import {TextareaAutosize} from '@mui/base/TextareaAutosize';
import "../cusAcknowlegment/AckForm/AckForm.scss";

const AdditionalComments = ({additionalComments}) => {

    return (
        <div className="group-wrapper add-comments">
            <div className="input-field-header">Additional Comments:</div>
            <div>The comments area allows you to enter details not captured above. For example, you may add additional
                values for text programs.
            </div>
            <TextareaAutosize
                minRows={8}
                value={additionalComments.comments}
                disabled
                name="comments"
                style={{width: "100%", fontFamily: "Arial, sans-serif"}}
            />
            <div className="group-bottom"></div>
        </div>
    );
};

export default AdditionalComments;