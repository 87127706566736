import axios from "axios";

export async function retrieveSignedCIAF(vinNumber, bundleId, bundleVersion) {
	const req = {
		vin: vinNumber,
		bundleId: bundleId,
		bundleVersion: bundleVersion,
		originationSource: "CONNECT_UI",
	};

	let activeEnv = "";
	let gateway = "";
	if (window.location.hostname === "localhost") {
		gateway = process.env.REACT_APP_BACKEND_GATEWAY;
		activeEnv = process.env.REACT_APP_ACTIVE_ENV;
	} else {
		gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
		activeEnv = window._env_.REACT_APP_ACTIVE_ENV;
	}
	const tonicToken = sessionStorage.getItem("id_token");

	const payload = JSON.stringify(req);
	const response = await axios({
		method: "post",
		data: payload,
		url: gateway + "/incentives/acknowledgement/ciafRetrieve",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});
	return response;
}

