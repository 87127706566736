import {useState, useContext, useEffect} from "react";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import "../cusAcknowlegment/AckForm/AckForm.scss";
import {GlobalContext} from "../../../GlobalService";
import { getFormattedDate } from "./DateFormatter";
import { PURCHASER_ACKNOWLEDGEMENT } from "../../../common/AppConstants";

const PurchaserAcknowledgement = ({acknowledgementData, formSigned, sentToCustomer}) => {
    const [boxChecked, setBoxChecked] = useState(false);
    const {signatureURL, completeForm, setDisablePurchaserAcknowledgement,} = useContext(GlobalContext);


    const handleChange = () => {
        setBoxChecked(!boxChecked);
    }

    let currentDate = new Date();

    useEffect(() => {
        if (boxChecked) {
            setDisablePurchaserAcknowledgement(false);
        } else {
            setDisablePurchaserAcknowledgement(true);
        }
    }, [boxChecked]);


    return (
        <div className="group-wrapper">
            <div className="input-field-header-black">
                Purchaser's Acknowledgement
            </div>
            { PURCHASER_ACKNOWLEDGEMENT(acknowledgementData?.deliveryDate) }
            <div className="checkbox_area">
                <Checkbox
                    style={{
                        transform: "scale(1.2)",
                        padding: 0,
                    }}
                    color="primary"
                    value={boxChecked}
                    disabled={formSigned || !sentToCustomer}
                    onChange={handleChange}
                />
                &nbsp;&nbsp; Purchaser acknowledges that all the information is
                correct.
            </div>
            <div>
                <Grid container spacing={6}>
                    <Grid item xs={4} sm={3}>
                        <div className="input-label">Date</div>
                        <div className="field-value">{getFormattedDate(currentDate)}</div>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <div className="input-label">
                            By signing below you are acknowledging and agreeing
                            to the information provided above:
                        </div>
                        {!completeForm ?
                            <TextField
                                id="customer-signature-box"
                                variant="outlined"
                                disabled
                                size="small"
                                fullWidth>
                            </TextField> :
                            <div className="signature-container">
                                <img
                                    src={signatureURL}
                                    alt="my signature"
                                    className="signatureimg2"

                                />
                            </div>
                        }

                    </Grid>
                </Grid>
            </div>
            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );
};

export default PurchaserAcknowledgement;