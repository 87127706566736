import axios from "axios";

let gateway = "";
let activeEnv = "";
if (window.location.hostname === "localhost") {
	gateway = process.env.REACT_APP_BACKEND_GATEWAY;
	activeEnv = process.env.REACT_APP_ACTIVE_ENV;
} else {
	gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
	activeEnv = window._env_.REACT_APP_ACTIVE_ENV;
}
export async function sendCode(profileId, email) {
	const tonicToken = sessionStorage.getItem("id_token");
	const req = {
		channel: "EMAIL",
		phoneNumber: "",
		profileId: profileId,
		email: email,
	};

	const payload = JSON.stringify(req);
	const response = await axios({
		method: "post",
		data: payload,
		url: gateway + "/customer/cdi/otp/send",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response;
}

export async function confirmCode(code, profileId) {
	const tonicToken = sessionStorage.getItem("id_token");

	const req = {
		code: code,
		profileId: profileId,
	};

	const payload = JSON.stringify(req);
	const response = await axios({
		method: "post",
		data: payload,
		url: gateway + "/customer/cdi/otp/confirm",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response;
}

