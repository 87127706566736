import { getFormattedDateNoUTC } from "../client/components/cusAcknowlegment/DateFormatter";

export const PURCHASER_ACKNOWLEDGEMENT = (deliveryDate) => {
    return (
        <div>
            <ul className="purchasers-bullet">
                <li>
                    I am the ultimate purchaser or lessee of the vehicle bearing
                    this vehicle identification number, which was sold/leased to
                    me by the Dealer, named below.
                </li>
                <li>
                    This vehicle was not purchased/leased for export or resale
                    and I took delivery on:
                    <span className="date-container">
                        {getFormattedDateNoUTC(deliveryDate)}
                    </span>
                </li>
                <li>
                    I acknowledge receipt of incentive(s) described above and
                    release GM from any future claim or obligation for
                    incentives on this unit.
                </li>
            </ul>
        </div>
    );
};

export const DEALER_ACKNOWLEDGEMENT =
    "The undersigned person, a Dealer Representative, certifies that the information on this application is true and correct and the incentive payments have been provided to" +
    " the said purchaser/lessee who has taken delivery of the referenced unit through this dealership and that properly completed accurate delivery data has been forwarded to General Motors.";

export const DEALER_NOTE = (
    <div>
        <span style={{ fontWeight: "bold" }}>Dealer Note: </span>This is a
        required document and it must be completed, signed, and retained in
        EVERY DEAL FILE for all customers even if there are no incentives or
        rate support available. A copy of the completed form should be provided
        to the customer. (GM379509-08012018) (12/01/2021)
    </div>
);

export const CUSTOMER_AGREEMENT = (
    <div>
        <ol className="cust-agree-list">
            <li style={{ "list-style-type": "decimal" }}>
                By signing this form, the Purchaser acknowledges the following:
            </li>
            <ol className="indented-list" type="A">
                <li id="list">
                    I have confirmed that the applicable price shown on line one
                    (1) above is correct.
                </li>
                <li id="list">
                    Receipt of the vehicle designated above and a copy of this
                    form.
                </li>
                <li id="list">
                    The Purchaser has read the GM Vehicle Purchase Program Rules
                    and Guidelines for the appropriate program.
                </li>
                <li id="list">
                    The Purchaser agrees that he/she will not violate any
                    Program provision.
                </li>
                <li id="list">
                    Penalties for violation of Program provisions may include
                    one or more of the following:
                </li>
                <ol className="indented-list" type="i">
                    <li id="sub">Termination of Program privileges.</li>
                    <li id="sub">
                        Requirement that the Purchaser or Employee reimburse
                        General Motors for the amount of any dealer allowance
                        paid.
                    </li>
                    <li id="sub">
                        Disciplinary action up to and including termination of
                        employment (for GM Employees).
                    </li>
                </ol>
            </ol>
        </ol>
        <br />
    </div>
);

export const T27_CUSTOMER_AGREEMENT = (
    <div>
        <ol className="cust-agree-list">
            <li style={{ "list-style-type": "decimal" }}>
                By signing this form, the Purchaser acknowledges the following:
            </li>
            <ol className="indented-list" type="A">
                <li id="list">
                    I have confirmed that the applicable price shown on line one
                    (1) above is correct.
                </li>
                <li id="list">
                    Receipt of the vehicle designated above and a copy of this
                    form.
                </li>
                <li id="list">
                    I am a full time employee of this dealership or a non-GM
                    franchise dealership and have read the GM Vehicle Purchase
                    Program Rules and Guidelines.
                </li>
                <li id="list">I agree not to violate any Program provision.</li>
                <li id="list">
                    Penalties for violation of Program provisions may include
                    suspension or termination of program privileges.
                </li>
            </ol>
        </ol>
        <br />
    </div>
);

export const DEALER_AGREEMENT = (
    <div>
        <ol className="dealer-agree-list">
            <li style={{ "list-style-type": "decimal" }}>
                By signing this form, the dealer agrees to the following:
            </li>
            <ol className="indented-list" type="A">
                <li id="list">
                    Assume General Motor's obligation for delivery of the
                    vehicle.
                </li>
                <li id="list">
                    Collect from the purchaser the amount specified in the
                    Purchase Contract.
                </li>
                <li id="list">
                    Comply with the Rules and Guidelines of The Program.
                </li>
                <li id="list">Review the Factory Invoice with the customer</li>
                <li id="list">
                    Provide purchaser with all applicable and compatible
                    incentives.
                </li>
                <li id="list">
                    Maintain the original copy of this form in the deal jacket.
                </li>
            </ol>
            <li style={{ "list-style-type": "decimal" }}>
                By signing below, the Dealer acknowledges having read The
                Program Rules and Guidelines and agrees to the following:
            </li>
            <ol className="indented-list" type="A">
                <li id="list">
                    Comply with the terms and conditions contained in The
                    Program Rules and Guidelines.
                </li>
                <li id="list">
                    Violation of any Program provision by the Dealer or anyone
                    acting on behalf of the Dealer may result in the Dealer
                    being:
                </li>
                <ol className="indented-list" type="i">
                    <li id="sub">
                        Declared ineligible to participate further in the
                        Program.
                    </li>
                    <li id="sub">
                        Charged back on any incentive or allowance paid by
                        General Motors on transactions in which violations
                        occur.
                    </li>
                </ol>
            </ol>
        </ol>
    </div>
);

export const T27_DEALER_AGREEMENT = (
    <div>
        <ol className="dealer-agree-list">
            <li style={{ "list-style-type": "decimal" }}>
                The dealer acknowledges having read both the Program Rules and
                Guidelines and the applicable incentive bulletin.
            </li>
            <li style={{ "list-style-type": "decimal" }}>
                The dealer has verified the customer is an eligible employee of
                the dealership and that the vehicle will be titled and
                registered in the employee's name.
            </li>
            <li style={{ "list-style-type": "decimal" }}>
                By signing this form the dealer agrees to the following:
            </li>
            <ol className="indented-list" type="A">
                <li id="list">
                    Comply with the Rules and Guidelines of the program and the
                    applicable incentive bulletin.
                </li>
                <li id="list">Review the factory invoice with the customer.</li>
                <li id="list">
                    Maintain the original completed and signed copy of this form
                    in the deal jacket.
                </li>
                <li id="list">
                    Violation of any program provision by the dealer or anyone
                    acting on behalf of the Dealer may result in the dealer
                    being:
                </li>
                <ol className="indented-list" type="i">
                    <li id="sub">
                        Declared ineligible to participate further in the
                        program.
                    </li>
                    <li id="sub">
                        Charged back on any of the incentives or allowance paid
                        by General Motors on the transactions in which
                        violations occur.
                    </li>
                </ol>
            </ol>
        </ol>
    </div>
);

export const OR_DISCLOSURE = (
    <ul className="disclosure-list">
        <li style={{ "list-style-type": "disc" }}>
            A documentation fee is specified in the applicable state law.
        </li>
        <br />
        <div>
            <b>OR</b>
            <br />
            <br />
        </div>
        <li style={{ "list-style-type": "disc" }}>
            The dealership charges all customers the same documentation fee
            (excluding customers purchasing under the GM Employee, Military,
            Executive Referral and Dealership Employee Programs).
        </li>
    </ul>
);

export const AND_DISCLOSURE = (
    <ul className="disclosure-list">
        <li style={{ "list-style-type": "disc" }}>
            A documentation fee is specified in the applicable state law.
        </li>
        <br />
        <div>
            <b>AND</b>
            <br />
            <br />
        </div>
        <li style={{ "list-style-type": "disc" }}>
            The dealership charges all customers the same documentation fee.
        </li>
    </ul>
);

export const AUTH_CODE_TOOLTIP =
    "PRINTOUT OF THE EMPLOYEE'S AUTHORIZATION NUMBER MUST BE KEPT IN THE DEAL JACKET.";

export const CDA_SPECIFIC_DISCLOSURE =
    "A CDA form that is complete and accurate but is deficient solely because it does not meet the 30-day requirement above is subject to a chargeback equal to the lesser of the VPP allowances or $500 for that sale. " +
    "Failure to meet any other VPP program requirements, or failure to have a complete and accurate CDA form on file, may result in the chargeback of all VPP allowances.";
