import { useEffect, useState, useContext } from "react";
import { CircularProgress, Divider, Grid } from "@mui/material";
import { searchAuthorization } from "../../services/LLOBService/LLOBService";
import "../cusAcknowlegment/AckForm/AckForm.scss";
import { GlobalContext } from "../../../GlobalService";

/**
 * Discount Information Section
 * Displays pm the acknowledgment form when pricing level
 * is EMP, SUP, QRD. Handles JSON related to discount information
 * section
 * @param {*} props
 * @returns
 */
const DiscountInformation = ({
    discount,
    birthday,
    customerInformationSection,
    acknowledgementData,
}) => {
    const {
        authType,
        approvalNumber,
        approvalDate,
        authorizationNumber,
        pricingForAll,
        postalCode,
        incentiveCode,
        programNbr: programNumber,
    } = discount || {};
    const {
        siteCode: dealerCode,
        brandNm: divisionCode,
        bacCode,
    } = acknowledgementData || {};
    const { firstName, lastName } = customerInformationSection || {};

    const { setSupplierMap } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    // Fields provided by LLOB
    const [supplier, setSupplier] = useState(discount?.supplier);
    const [programName, setProgramName] = useState(discount?.programName);
    const [participant, setParticipant] = useState(discount?.participant);
    const [relationship, setRelationship] = useState(discount?.relationship);

    const isLLOBDiscount = authType === "GM-VPP" || authType === "GM-ACERT";
    const customerName = `${firstName ?? ""} ${lastName ?? ""}`.trim();

    // Update the form to clear the prepopulated fields from LLOB
    const clearLLOBFields = () => {
        // Update the form
        setParticipant("");
        setRelationship("");
        setSupplier("N/A");
        setSupplierMap((prev) => ({
            ...prev,
            [programNumber]: "N/A",
        }));
    };

    // Pull the discount information from LLOB
    const getLLOBDiscountInfo = async () => {
        // Populate with data from acknowledgment form
        const dealerInfo = { dealerCode, divisionCode };

        setLoading(true);
        await searchAuthorization(authorizationNumber, bacCode, { dealerInfo })
            .then((res) => {
                const authorizationData = res?.data?.data || {};
                const {
                    participantFirstName,
                    participantLastName,
                    authRelationship,
                    authCompanyName,
                    participantProgramName,
                } = authorizationData || {};

                // Is successful if we get the metadata from LLOB
                const isSuccessful =
                    participantFirstName ||
                    participantLastName ||
                    authRelationship ||
                    authCompanyName;

                if (isSuccessful) {
                    const participant = `${participantFirstName ?? ""} ${
                        participantLastName ?? ""
                    }`.trim();

                    // Always show N/A for VPP Incentives; ACERT depends on data returned from LLOB
                    let supplierName = "N/A";
                    if (authType === "GM-ACERT") {
                        supplierName = authCompanyName || supplierName;
                    }

                    if (participant) setParticipant(participant);
                    if (authRelationship) setRelationship(authRelationship);
                    
                    setProgramName(programName || participantProgramName);
                    setSupplier(supplierName);
                    setSupplierMap((prev) => ({
                        ...prev,
                        [programNumber]: supplierName,
                    }));
                }

                // Handle bad data from LLOB
                switch (responseReason) {
                    case "Authorization not found.": // Invalid Auth Number
                        clearLLOBFields();
                        break;
                    //TODO: Handle other potential error scenarios
                    default:
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (isLLOBDiscount) getLLOBDiscountInfo();
    }, [isLLOBDiscount]);

    return (
        <div className="group-wrapper" id="ciaf-discount-information">
            <div className="input-field-header">Discount Information</div>
            {loading ? (
                <CircularProgress className="loader" />
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={4}>
                        <div className="input-label">Program Name</div>
                        <div id="program-name" className="field-value">
                            {programName}
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <div className="input-label">Incentive Code</div>
                        <div id="incentive-code" className="field-value">
                            {incentiveCode}
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <div className="input-label">
                            <label for="postal-code">Postal Code</label>
                        </div>
                        <div id="postal-code" className="field-value">
                            {postalCode}
                        </div>
                    </Grid>
                    {pricingForAll === "N" && (
                        <>
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">
                                    Customer Date of Birth
                                </div>
                                <div id="birthday" className="field-value">
                                    {birthday}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">
                                    Eligible Participant
                                </div>
                                <div
                                    id="eligible-participant"
                                    className="field-value"
                                >
                                    {participant}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">
                                    Purchaser's Name
                                </div>
                                <div
                                    id="purchaser-name"
                                    className="field-value"
                                >
                                    {customerName}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">
                                    Relationship to Eligible Participant
                                </div>
                                <div id="relationship" className="field-value">
                                    {relationship}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">Approval Date</div>
                                <div id="approvalDate" className="field-value">
                                    {approvalDate}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className="input-label">
                                    {authType === "GM-CERT"
                                        ? "Certificate #"
                                        : "Authorization #"}
                                </div>
                                <div
                                    id="authorization-number"
                                    className="field-value"
                                >
                                    {authorizationNumber}
                                </div>
                            </Grid>
                            {isLLOBDiscount && (
                                <>
                                    <Grid item xs={6} sm={4}>
                                        <div className="input-label">
                                            Approval Code
                                        </div>
                                        <div
                                            id="approval-code"
                                            className="field-value"
                                        >
                                            {approvalNumber}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <div className="input-label">
                                            Supplier
                                        </div>
                                        <div
                                            id="supplier"
                                            className="field-value"
                                        >
                                            {supplier}
                                        </div>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            )}

            <div className="group-bottom"></div>
            <Divider />
        </div>
    );
};

export default DiscountInformation;
