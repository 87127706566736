import { useState, useEffect } from "react";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {DEALER_AGREEMENT, T27_DEALER_AGREEMENT} from "../../../common/AppConstants";
import "../cusAcknowlegment/AckForm/AckForm.scss";

const DealerAgreement = ({
                             priceLevel,
                             acknowledgementData,
                             pricingForAll,
                             formulaPricing
                         }) => {
    const [hasGmDealershipEmployeeST, setHasGmDealershipEmployeeST] = useState(false);


    //Sets color of disable text to black
    const fontColor = {
        style: {color: "rgb(50, 50, 50)"},
    };


    function formatDate(tempDate) {
        let date = new Date(tempDate);
        let result = date.toLocaleDateString(undefined, { // you can use undefined as first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })

        return result;
    }

    useEffect(() => {

        const gmDealershipEmployeeST = ["TX18", "CS72", "CS71"]

        acknowledgementData.acknowledgementIncentiveData.forEach(incentive => {

            if (gmDealershipEmployeeST.includes(incentive.defaultProgramName)) {
                setHasGmDealershipEmployeeST(true);
            }

        });

    }, [acknowledgementData.acknowledgementIncentiveData]);


    return (
        <div className="group-wrapper">
            {pricingForAll !== "Y" && <div className="input-field-header-black">Dealer Agreement</div>}
            {(pricingForAll !== "Y" && !formulaPricing) && ((priceLevel === "SUP" || priceLevel === "EMP") && !hasGmDealershipEmployeeST ? DEALER_AGREEMENT : T27_DEALER_AGREEMENT)}
            <div className="checkbox_area">
                <Checkbox
                    style={{
                        transform: "scale(1.2)",
                        padding: 0,
                    }}
                    checked={true}
                    color="primary"
                />{" "}
                The authorized Dealer acknowledges that all the information above is correct.
            </div>
            <div>
                <Grid container spacing={6}>
                    <Grid item xs={6} sm={3}>
                        <div className="input-label">Date</div>
                        {formatDate(acknowledgementData.cdaDealerSignedDt)}
                    </Grid>
                    <Grid item xs={6} sm={9}>
                    <div className="input-label">
                                    By signing below you are acknowledging and agreeing
                                    to the information provided above:
                                </div>
                                <TextField
                                    id="customer-signature-box"
                                    value={acknowledgementData.dealerNm}
                                    disabled
                                    variant="outlined"
                                    inputProps={fontColor}
                                    size="small"
                                    fullWidth
                                ></TextField>
                    </Grid>
                </Grid>
            </div>
            <div className="group-bottom"></div>
            <Divider/>
        </div>
    );
};

export default DealerAgreement;
