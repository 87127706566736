import { OR_DISCLOSURE, AND_DISCLOSURE } from "../../../common/AppConstants";
import { NumericFormat as NumberFormat } from 'react-number-format';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
const DocFeeDisclosure = (props) => {
    return (
        <div className="group-wrapper">
            {props.disclosureType == "AND" && (AND_DISCLOSURE)}
            {props.disclosureType == "OR" && (OR_DISCLOSURE)}
            
            <br/>
            {props.programType !==  "CIAF" && props.disclosureType !== "" && (
                <>
                    <div className="doc-fee-label">Documentation Fee</div>
                    <div className="doc-fee-container">
                        <AttachMoneyIcon />
                        <NumberFormat
                            value={props.acknowledgementData.documentFee}
                            name="amountOne"
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            variant="outlined"
                            size="small"
                            style={{backgroundColor: "white"}}
                             isAllowed={(values) => {
                              const {floatValue} = values;
                              return floatValue <= 9999.99;
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
export default DocFeeDisclosure;