import axios from "axios";

let gateway = "";
let activeEnv = "";

if (window.location.hostname === "localhost") {
	gateway = process.env.REACT_APP_BACKEND_GATEWAY;
	activeEnv = process.env.REACT_APP_ACTIVE_ENV;
} else {
	gateway = window._env_.REACT_APP_BACKEND_GATEWAY;
	activeEnv = window._env_.REACT_APP_ACTIVE_ENV;
}

export async function getAcknowledgementData(state) {
	const tonicToken = sessionStorage.getItem("id_token");

	const response = await axios({
		method: "get",
		url: gateway + "/incentives/bundle/getAcknowledgementData/" + state,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response.data;
}

export async function processAcknowledgementForm(formData) {
	// const date = new Date();
	//Thu Jun 12 2021 10:00:00 GMT-0400 (Eastern Daylight Time)
	// const newDate = date.toTimeString();
	//10:00:00 GMT-0400 (Eastern Daylight Time)
	// const newDate1 = newDate.split(" ")[1];
	//GMT-0400

	const timeZone = new Date().toTimeString().split(" ")[1];

	let exampleRes = {
		andLegalText: "N",
		attributeChangedFlg: false,
		bacCode: formData.bacCode,
		bundleId: formData.bundleId,
		bundleVersion: formData.bundleVersion.toString(),
		cdaCustomerAckFlg: formData.cdaCustomerAckFlg,
		cdaCustomerSignedDt: "2022-02-18",
		cdaDealerAckFlg: formData.cdaDealerAckFlg,
		cdaDealerSignedDt: formData.cdaDealerSignedDt,
		cdaTotalAmount: formData.cdaTotalAmount,
		ciafCommentText: formData.ciafCommentText,
		ciafCustomerAckFlg: false,
		ciafCustomerSignedDt: formData.ciafCustomerSignedDt,
		ciafDealerAckFlg: formData.ciafDealerAckFlg,
		ciafDealerSignedDt: formData.ciafDealerSignedDt,
		customerDealerAgreementTableRequestList: formData.customerDealerAgreementTableRequestList,
		customerEmail: formData.customerEmail,
		customerIncentiveProgramAcknowledgementRequestList:
			formData.customerIncentiveProgramAcknowledgementRequestList,
		customerProfileId: formData.customerProfileId,
		customerSnapshotId: formData.customerSnapshotId,
		dealerApprovalDate: formData.dealerApprovalDate,
		dealerNm: formData.dealerName,
		dealerSignatureContent: formData.dealerSignatureContent,
		dealerUserId: formData.dealerUserId,
		deliveryDate: formData.deliveryDate,
		deliveryType: "0" + formData.deliveryType,
		discountInformationRequestList: formData.discountInformationRequestList,
		discountTableRowText: formData.discountTableRowText,
		division: formData.division,
		documentFee: 0,
		firstName: formData.firstName,
		incentiveList: formData.incentiveList,
		incentiveTotalAmount: formData.incentiveTotalAmount,
		isDiscountTableVisible: formData.isDiscountTableVisible,
		lastName: formData.lastName,
		make: formData.make,
		model: formData.model,
		modificationBy: formData.modificationBy,
		orderType: "070",
		originationSource: formData.originationSource,
		partnerNm: formData.partnerNm,
		pricingLevelCd: formData.pricingLevelCd,
		sellSource: formData.sellSource,
		sendToCustomerFlg: true,
		submitToPcfFlg: true,
		signatureType: "E",
		siteCode: formData.siteCode,
		vin: formData.vin,
		year: formData.year,
		zipCode: formData.zipCode,
		timeZone: timeZone,
	};

	const payload = JSON.stringify(exampleRes);
	const tonicToken = sessionStorage.getItem("id_token");

	const response = await axios({
		method: "post",
		data: payload,
		url: gateway + "/incentives/bundle/processAcknowledgementForm",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response;
}

export async function recordAcknowledgement(formData, signatureURL, initialURL) {
	let signature = signatureURL.split(",");
	let initials = initialURL;
	if (initials !== null && initials !== "") {
		initials = initialURL.split(",");
	}

	const req = {
		bacCode: formData.bacCode,
		bundleId: formData.bundleId,
		bundleVersion: formData.bundleVersion.toString(),
		content: "",
		contentType: "",
		customerInitialsContent: initialURL ? initials[1] : signature[1],
		customerSignatureContent: signature[1],
		fileName: "",
		ipaddress: "string",
		originationSource: "CONNECT_UI",
		sellSource: formData.sellSource,
		signatureType: "E",
		siteCode: formData.siteCode,
		vin: formData.vin,
		isCiafChecked: "Y",
		isCdaChecked: "Y",
	};

	const payload = JSON.stringify(req);
	const tonicToken = sessionStorage.getItem("id_token");

	const response = await axios({
		method: "post",
		data: payload,
		url: gateway + "/incentives/acknowledgement/record",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	let res = {
		file: signatureURL,
		response: response,
	};

	return res;
}

export async function updateCiafSignedFlag(state) {
	const tonicToken = sessionStorage.getItem("id_token");
	const response = await axios({
		method: "get",
		url: gateway + "/incentives/bundle/updateCiafSignedFlag/" + state + "?signatureType=E",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});

	return response.data;
}

export async function getCustomerInfoRead(snapshotId) {
	const tonicToken = sessionStorage.getItem("id_token");
	const snapArray = [snapshotId];

	const req = {
		sourceSystemCode: "204624",
		countryCode: "US",
		listOfCustSnapGuids: snapArray,
	};
	const payload = JSON.stringify(req);

	const response = await axios({
		method: "get",
		data: payload,
		url: gateway + "/customer/custsnapshots/read/" + snapshotId,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${tonicToken}`,
			ActiveEnv: activeEnv,
		},
	});
	return response;
}

