import axios from "axios";

const HOST = window.location.hostname;
const APP_ENV = HOST === "localhost" ? process.env : window._env_;
const URL = APP_ENV.REACT_APP_BACKEND_GATEWAY;
const ACTIVE_ENV = APP_ENV.REACT_APP_ACTIVE_ENV;

/**
 * Search for an authorization in LLOB
 *
 * @POST
 * @param {String} authorizationNumber The authorization number to search for in LLOB
 * @param {String} bac The BAC code of the dealer
 * @param {Object} searchData The data to send LLOB for the authorization search
 * @param {AbortSignal} signal The signal to cancel the request
 */
export async function searchAuthorization(
    authorizationNumber = "",
    bac = "",
    searchData = {},
    signal
) {
    const error = !authorizationNumber || !bac;
    if (error) return;

    const { dealerCode, divisionCode: divCode } = searchData?.dealerInfo || {};
    
    let divisionCode = divCode;
    if (divisionCode === "Chevy") divisionCode = "Chevrolet";

    const payload = {
        inputs: {
            authorizationNumber,
            //* Need to remove leading zeros for llob to find the BAC
            bac: bac.replace(/^0+/, ""),
            // Sending placeholder values when customers use the api
            dealerUserID: "CUSTOMER",
            dealerUserName: "CUSTOMER",
            ...(dealerCode && { dealerCode }),
            ...(divisionCode && { divisionCode }),
        },
    };
    const axiosURL = `${URL}/llob/authSearch`;
    const axiosData = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("id_token")}`,
            ActiveEnv: ACTIVE_ENV,
        },
        signal,
    };

    const response = await axios.post(axiosURL, payload, axiosData);
    return response;
}
